try {
    window.bootstrap = require("bootstrap5/dist/js/bootstrap.bundle");
    require("lazysizes");
    require("mmenu-light");
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
    console.error("CSRF token not found!");
}

window.addEventListener('load', setHeaderMargin);
window.addEventListener('resize', setHeaderMargin);
window.addEventListener('scroll', setHeaderMargin);

function setHeaderMargin(){
    var main = document.querySelector('main');
    var header = document.querySelector('header');
    main.style.marginTop = header.clientHeight+'px';
}

document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});